import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import StorageProvider from "./contexts/StorageContext";
import { ModalError, Modal } from "./genericComponents/modals";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CssBaseline, makeStyles } from "@material-ui/core";
Chart.plugins.unregister(ChartDataLabels);

Chart.pluginService.register({
  beforeDraw: function (chart, easing) {
    if (
      chart.config.options.chartArea &&
      chart.config.options.chartArea.backgroundColor
    ) {
      const ctx = chart.chart.ctx;
      const chartArea = chart.chartArea;

      ctx.save();
      ctx.fillStyle = chart.config.options.chartArea.backgroundColor;
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    }
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
}));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div className={useStyles.root}>
    <React.StrictMode>
      <StorageProvider>
        <CssBaseline />
        <ModalError />
        <Modal />
        <App />
      </StorageProvider>
    </React.StrictMode>
  </div>
);

reportWebVitals();
